export default {
    "default_negotiations": [
        "direct_chief",
        "senior_chief"
    ],
    "stage_negotiations": [],
    "on_disagree_go_to_self_negotiation": true,
    "types": {
        "self": {
            "showName": "Сотрудник"
        },
        "direct_chief": {
            "showName": "Непосредственный руководитель"
        },
        "senior_chief": {
            "showName": "Вышестоящий руководитель"
        },
        "other": {
            "showName": "Ручной"
        }
    }
}