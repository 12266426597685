export default {
    "sectionsBlockHeaderTemplate": "componentsCompany/gemotest/CardSectionsBlockHeader",
    "sectionsBlockFooterTemplate": "componentsCompany/gemotest/CardSectionsBlockFooter",
    "sections": {
        "library_manual_plan_fact": {
            "showName": "Цели",
            "emptyText": "Нет целей",
            "hideOnStagesIfEmpty": [
                "assessment",
                ""
            ],
            "fromLibrary": "library_manual_plan_fact",
            "fieldsAvailableForEdit": {
                "desc": {
                    "stages": [
                        "plan"
                    ]
                },
                "weight": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale.plan": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale.fact": {
                    "stages": [
                        "assessment"
                    ]
                },
                "comment.plan": {
                    "stages": [
                        "plan"
                    ],
                    "skipElementEditPermissionsCheck": true
                },
                "comment.assessment": {
                    "stages": [
                        "assessment"
                    ],
                    "skipElementEditPermissionsCheck": true
                }
            }
        }
    },
    "default_columns": [
        {
            "label": "Название цели",
            "minWidth": 200,
            "template": "components/tableColumnTemplates/card/ElementNameTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Единица измерения",
            "width": 150,
            "template": "components/tableColumnTemplates/card/ElementLibraryUnitTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "План",
            "width": 140,
            "template": "components/configurableScales/tableColumnTemplates/ElementConfigurableScalePlanTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Комментарий к плану",
            "width": 180,
            "template": "components/tableColumnTemplates/card/ElementCommentsTableColumn",
            "bind": {
                "scope": "scope",
                "stage": "'plan'",
                "last-comment": "scope.row.last_comment_by_stage['plan']",
                "comments-count": "scope.row.comments_count_by_stage['plan']"
            }
        },
        {
            "label": "Факт",
            "width": 140,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "components/configurableScales/tableColumnTemplates/ElementConfigurableScaleFactTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Комментарий к факту",
            "width": 180,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "components/tableColumnTemplates/card/ElementCommentsTableColumn",
            "bind": {
                "scope": "scope",
                "stage": "'assessment'",
                "last-comment": "scope.row.last_comment_by_stage['fact']",
                "comments-count": "scope.row.comments_count_by_stage['fact']"
            }
        },
        {
            "label": "% выполнения",
            "prop": "scale.fact_percent",
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "width": 120
        },
        {
            "label": "",
            "template": "components/tableColumnTemplates/card/ElementActionsTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            },
            "align": "'center'",
            "width": 50
        }
    ]
}