export default {
    "types": {
        "plan": {
            "showName": "Постановка целей",
            "selected": false,
            "order": 1,
            "autostart": true,
            "canManageElementsBeforeDateStart": false,
            "canManageElementsAfterDateEnd": false,
            "hideDates": true
        },
        "assessment": {
            "showName": "Подведение итогов",
            "selected": false,
            "order": 3,
            "autostart": true,
            "canManageElementsBeforeDateStart": false,
            "canManageElementsAfterDateEnd": false,
            "hideDates": true
        }
    }
}